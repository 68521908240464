import './About.scss'

import React from 'react'
import Modal from 'react-modal'
import { Button } from 'evergreen-ui'

import {buildDate, buildVersion} from '../lib/build'

class About extends React.Component {

  state = {
    isVisible: false,
  }
  showAbout = () => {
    this.setState({isVisible: true})
  }
  hideAbout = () => {
    this.setState({isVisible: false})
  }

  render() {
    const that = this
    const {
      showAbout, hideAbout,
      state: {isVisible},
    } = that
    
    return (
      <span className='about-button'>
        <Button
          className='about'
          appearance='minimal'
          iconBefore='help'
          height={24}
          onClick={showAbout}
         />

        <Modal isOpen={isVisible} >
          <div className='about-modal'>
            <h2>This is <img src='/images/compass-icon.png' className='logo' alt='' />NORTHSTAR!</h2>
            <div>Revision {buildVersion()}, {buildDate()}</div>
            <div className='action-bar'>
              <Button appearance='minimal' onClick={hideAbout}>close</Button>
            </div>
          </div>
        </Modal>
      </span>
    )
  }
}

export default About
