import './TodoItem.scss'

import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Select } from 'evergreen-ui'
import { toast } from 'react-toastify'


import TodoState from './TodoState'
import EditableTextField from '../EditableTextField'
import {EDIT_TODO, MOVE_TODO, COMPLETE_TODO, ONEONONE_DETAIL_QUERY} from '../../lib/queries'
import {PATH_ONEONONES} from '../../constants'
import DragHandle from '../DragHandle'

const ACTIONS = {
  MOVE_TO_TOP: 'top',
  COMPLETE: 'complete'
}

// TODO convert TodoItem to a function component
function ageInDays(todo) {
  if (!todo) return undefined
  const {createdAt} = todo
  const age = Date.now() - new Date(createdAt)
  const ONE_DAY = 1000 * 60 * 60 * 24
  return Math.floor(age / ONE_DAY)
}

function ageStatus(todo) {
  if (!todo) return undefined
  const {completedAt} = todo
  if (completedAt) {
    return undefined
  }
  
  const age = this.ageInDays()
  if (age > 21) return 'ignored'
  if (age > 14) return 'stale'
  return undefined
}

function updateTodo(e, todo, client) {
  const {id} = todo
  const action = e.target.value
  e.target.blur()

  if (action === ACTIONS.MOVE_TO_TOP) {
    client.mutate({
      errorPolicy: 'all',
      fetchResults: true,
      mutation: MOVE_TODO,
      variables: {id, toBeginning: true}
    })
    .then((r) => {
      client.resetStore()
    })
    .catch((err) => {console.log(`error moving TODO ${id} to top`, err)})
  }
  if (action === ACTIONS.COMPLETE) {
    client.mutate({
      errorPolicy: 'all',
      fetchResults: true,
      mutation: COMPLETE_TODO,
      variables: {id}
    })
    .then((r) => {
      toast('Todo completed. Woo hoo!', {autoClose: 1000})
      client.resetStore()
    })
    .catch((err) => {console.log(`error completing TODO ${id}`, err)})
  }
}

function TodoItem(props) {
  const {todo, readOnly, compact, draggable, oneOnOneId} = props
  const {text, completedAt, oneOnOne, promisee} = todo
  const ageStyle = ageStatus()

  const refetchQueries = (oneOnOneId) ? [{query: ONEONONE_DETAIL_QUERY, variables: {id: oneOnOneId}}] : []
  const [saveText, {client}] = useMutation(EDIT_TODO, {
    refetchQueries,
    onCompleted: () => {
      toast('Todo updated', {autoClose: 1000})
    }
  })

  if (readOnly) {
    if (completedAt) {
      return (
        <li className='todo-item'>
          <span className='completed-todo'>{text}</span>
        </li>
      )
    }
    return (
      <li className='todo-item'>
        <EditableTextField
          text={text}
          onSave={() => {}}
          readOnly={true}
          cssClass={ageStyle}
          />
      </li>
    )
  }

  const age = ageInDays(todo)
  const actionComponent = (ageStyle)
    ? <Select value='' onChange={(e) => { updateTodo(e, todo, client) }} className='old-todo-action' appearance='minimal'>
        <option value=''>{age} days</option>
        <option value={ACTIONS.MOVE_TO_TOP}>move to top</option>
        <option value={ACTIONS.COMPLETE}>mark done</option>
      </Select>
    : undefined

  return (
    <li className='todo-item'>
      <TodoState todo={todo} oneOnOneId={oneOnOneId} />
      {draggable && <DragHandle />}
      {(oneOnOne && ! compact)
        ? <span className='todo-one-on-one'>[<Link to={`${PATH_ONEONONES}/${oneOnOne.id}`}>{oneOnOne.name}</Link>]</span>
        : undefined
      }
      {(promisee && ! compact)
        ? <span className='todo-promisee'>({promisee.name})</span>
        : undefined
      }
      {(completedAt)
        ? <span className='completed-todo'>{text}</span>
        : (
          <EditableTextField
            text={text}
            actionComponent={actionComponent}
            cssClass={ageStyle}
            onSave={(text) => {
              saveText({ variables: { id: todo.id, text } }); return true;
            }}
            />
        )
      }
    </li>
  )
}

TodoItem.propTypes = {
  todo: PropTypes.object,
  compact: PropTypes.bool,
  draggable: PropTypes.bool,
  readOnly: PropTypes.bool
}

export default withApollo(TodoItem)
