import './AppContent.scss'

import React from 'react'

export default (props) => {
  return (
    <div className='app-content'>
      {props.children}
    </div>
  )
}
