import './Signin.scss'

import React, {useState} from 'react'
import { browserHistory } from 'react-router'
import { useQuery, useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { toast } from 'react-toastify'

import NavBar from './NavBar'
import Loading from './Loading'

import { AUTH_TOKEN, PATH_SIGNED_IN_HOMEPAGE } from '../constants'

const AUTH_CHECK = gql`
  {
    me {
      id
      name
    }
  }
`

const SIGNUP_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!, $name: String!) {
    signUp(
      email: $email
      password: $password
      name: $name
    ) {
      token
    }
  }
`

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    signIn(
      login: $email
      password: $password
    ) {
      token
    }
  }
`

function Signin(props) {
  const [login, setLogin] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [signinToastId, setSigninToastId] = useState(undefined)

  const { loading, data, error } = useQuery(AUTH_CHECK);
  const [ mutation, {client} ] = useMutation(login ? LOGIN_MUTATION : SIGNUP_MUTATION, {
    onCompleted: (data) => {
      const { token } = (login) ? data.signIn : data.signUp

      if (signinToastId) {
        toast.dismiss(signinToastId)
        setSigninToastId(undefined)
      }

      saveUserData(token)
      client.resetStore()
      setTimeout(() => {browserHistory.push(PATH_SIGNED_IN_HOMEPAGE)}, 50)
    },
    onError: (errors) => {
      console.log(errors)
      const errs = errors.graphQLErrors || [{message: 'Sorry, something went wrong.'}]
      const message = errs[0].message

      if (signinToastId) toast.dismiss(signinToastId)
      setSigninToastId(toast(`Error ${actionText(login)} – ${message}`, { autoClose: false }))
    }
  })

  let content
  if (loading) {
    content = <Loading />
  }
  else if (data && data.me && data.me.id) {
    content = (<div>Here we go...</div>)
    setTimeout(() => {browserHistory.push(PATH_SIGNED_IN_HOMEPAGE)}, 50)
  }
  else if (error) {
    localStorage.removeItem(AUTH_TOKEN)
    client.resetStore()
  }

  return (      
    <div>
      <NavBar isSignedIn={false} />
      {(content)
        ? content
        : (<div>
            <h4 className="mv3">{login ? 'Sign In' : 'Sign Up'}</h4>
            
            <form className='signin' onSubmit={e => { 
                  e.preventDefault()
                  if (signinToastId) toast.dismiss(signinToastId)
                  setSigninToastId(toast(actionText(login), { autoClose: false }))

                  mutation({ variables: { email, password, name } })
            }}>
              {!login && (
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type="text"
                  placeholder="Name"
                />
              )}
              <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="text"
                placeholder="Email address"
              />
              <input
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
                
              <input type='submit' value={login ? 'sign in' : 'create account'} />

              <div className="toggle-mode" onClick={() => setLogin( !login )}>
                {login ? 'need to create an account?' : 'already have an account?'}
              </div>
            </form>
          </div>
          )
      }
    </div>
  )
}

function actionText(login) {
  return (login) ? 'signing in' : 'creating account'
}

function saveUserData(token) {
  localStorage.setItem(AUTH_TOKEN, token)
}

export default withApollo(Signin)