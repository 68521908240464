import './TodoList.scss'

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation, gql } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import { Button } from 'evergreen-ui'

import CreateTodoItem from './CreateTodoItem'
import TodoItem from './TodoItem'
import Loading from '../Loading'

import {TODO_QUERY, MOVE_TODO} from '../../lib/queries'

const SortableLI =  SortableElement(({value}) => value)

const SortableUL = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableLI key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  )
})

function filterItems(items, hideCompleted, showCompleted) {
  const recentCutoff = new Date(Date.now() - (5 * 60 * 1000))

  return items.filter((t) => {
    if (! t.completedAt) return true
    if (showCompleted) return true
    if (hideCompleted) return false
    const when = new Date(t.completedAt)
    return (when >= recentCutoff)
  })
}

function todoElements(items, compact) {
  return items.map((t) => <TodoItem todo={t} readOnly={compact} compact={compact} draggable={true} />)
}

function updateForSort(evt, todos) {
  const {oldIndex, newIndex} = evt
  const itemToMove = todos[oldIndex]
  const lastIndex = todos.length - 1
  
  const variables = { id: itemToMove.id }
  if (newIndex <= 0) {
    variables.toBeginning = true
  }
  else if (newIndex >= lastIndex) {
    variables.toEnd = true
  }
  else {
    // the todos list moves around under us when moving an item down the list, so we need to compensate in our offset calcs here
    const actualIndex = (newIndex > oldIndex) ? newIndex : newIndex - 1
    variables.afterId = todos[actualIndex].id
  }

  return variables
}

function TodoList(props) {
  const [showCompleted, setShowCompleted] = useState(false)

  const { loading, error, data } = useQuery(TODO_QUERY)
  const [moveTodo, {client}] = useMutation(MOVE_TODO, {
    update(cache, {data: {moveTodo}}) {
      const sortTarget = moveTodo.sort
      cache.modify({
        id: cache.identify(data.me),
        fields: {
          todos(currentTodos=[]) {
            const thisTodoRef = cache.identify({__typename: "Todo", id: moveTodo.id})
            // first remove the moving item from it's old position in the todo list
            currentTodos = currentTodos.filter(r => r.__ref !== thisTodoRef)
            // now find a todo with sort > sortTarget?
            const insertBefore = currentTodos.findIndex(ref => {
              const theTodo = client.readFragment({
                id: ref.__ref, // The value of the to-do item's unique identifier
                fragment: gql`
                  fragment ATodo on Todo {
                    id
                    sort
                  }
                `,
              })
              return (theTodo && theTodo.sort > sortTarget)
            })
            // and either insert the moving todo above the found todo, or append to the end of the list
            if (insertBefore > -1) {
              currentTodos.splice(insertBefore, 0, {__ref: thisTodoRef})
            } else {
              currentTodos.push({__ref: thisTodoRef})
            }
            return currentTodos
          }
        }
      })
    }
  })

  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <></>
  }

  const {compact, hideCompleted} = props
  const todos = filterItems(data.me.todos || [], hideCompleted, showCompleted)

  return (
    <div className='todo-list-container'>
      {! compact && <CreateTodoItem />}
      <SortableUL
        items={todoElements(todos, compact)}
        onSortEnd={(evt) => {
          const variables = updateForSort(evt, todos)
          moveTodo({ variables })
        }}
        lockAxis='y'
        useDragHandle
      />

      { !hideCompleted && !showCompleted &&
        <Button onClick={() => setShowCompleted(true)}>show recently completed</Button>
      }
      { !hideCompleted && showCompleted &&
        <Button onClick={() => setShowCompleted(false)}>hide completed</Button>
      }
    </div>
  )
}

TodoList.propTypes = {
  hideCompleted: PropTypes.bool,
  compact: PropTypes.bool,
}

export default withApollo(TodoList)