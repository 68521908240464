import './TeamList.scss'

import React from 'react'
import { useMutation, useQuery } from '@apollo/client'

import EditableTextField from './EditableTextField'
import Loading from './Loading'

import {TEAM_DETAIL_QUERY, EDIT_TEAM} from '../lib/queries'

function TeamView(props) {
  const id = props.params.id
  const {data, loading} = useQuery(TEAM_DETAIL_QUERY, { variables: { id } })
  const [saveName] = useMutation(EDIT_TEAM)

  if (loading) {
    return <Loading />
  }

  const team = data.team || {}
  const {name} = team

  return (
    <div className='team-detail-container'>
      <div className='team-name'>
        <EditableTextField
          text={name}
          onSave={(name) => {
            saveName({ variables: { id: team.id, name } }); return true;
          }}
          />
      </div>

      <ul className='members'>
        {(team.members || []).map((m) => <li key={`team-member-${m.id}`}>{m.name} ({m.email})</li>)}
      </ul>
    </div>
  )
}

export default TeamView