import './EditOneOnOne.scss'

import React, {useState} from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import Modal from 'react-modal'
import { TextInputField, Button } from 'evergreen-ui'

import {EDIT_ONEONONE, DELETE_ONEONONE} from '../../lib/queries'
import {PATH_ONEONONES} from '../../constants'

function OneOnOneView(props) {

  const {id} = props.oneOnOne.id
  const [isEditing, setIsEditing] = useState(false)
  const [name, setName] = useState(props.oneOnOne.name)
  const [saveName] = useMutation(EDIT_ONEONONE)
  const [deleteUno, {client}] = useMutation(DELETE_ONEONONE, {
    onCompleted: () => {
      client.resetStore()
      browserHistory.push(PATH_ONEONONES)
    }
  })

  const saveChanges = () => {
    if (name && name.length > 0) {
      saveName({variables: {id, name}})
      setIsEditing(false)
    }
  }
  
  return (
    <span className='edit-one-on-one'>
      <Button
        className='start-edit'
        appearance='minimal'
        iconBefore='edit'
        height={24}
        onClick={() => setIsEditing(true)}
      >edit</Button>

      <Modal
        isOpen={isEditing}
      >
        <h2>Edit One on One</h2>
        <form onSubmit={saveChanges}>
          <TextInputField
            label='Name'
            required
            isInvalid={(name||'').length < 1}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className='action-bar'>
            <Button appearance='minimal' onClick={saveChanges}>save</Button>
            <Button appearance='minimal' onClick={() => setIsEditing(false)}>cancel</Button>
          </div>
        </form>

        <h2>Delete One on One</h2>
        <div className='action-bar destructive'>
          <Button 
            intent='danger'
            iconBefore='trash'
            onClick={() => {
              if (window.confirm(`Are you sure you want to delete this One on One? Any agenda and journal notes will be destroyed and cannot be recovered.`)) {
                deleteUno({variables: { id}})
              }
            }}>delete...</Button>
        </div>
      </Modal>
    </span>
  )
}

OneOnOneView.propTypes = {
  oneOnOne: PropTypes.object.isRequired
}

export default OneOnOneView
