import './HomeView.scss'

import React from 'react'
import {Link} from 'react-router'

import {OneOnOneList} from './OneOnOne'
import {TodoList, CreateTodoItem} from './Todo'
import {CreateAgendaItem} from './Agenda'

import {PATH_ONEONONES, PATH_TODOS} from '../constants'

export default class HomeView extends React.Component {

  render() {
    return (
      <div>
        <p>Welcome to your day!</p>

        <div className='section'>
          <div className="oneonone-list">
            <h2><Link to={PATH_ONEONONES}>1:1s</Link></h2>
            <CreateAgendaItem placeholder='Add to a 1:1 agenda' />
            <OneOnOneList compact={true} />
          </div>
        </div>

        <div className='section'>
          <div className="todo-list">
            <h2><Link to={PATH_TODOS}>Your todo list</Link></h2>
            <CreateTodoItem />
            <TodoList compact={true} hideCompleted={true} />
          </div>
        </div>
      </div>
    )
  }
}
