import './EditableTextField.scss'

import React from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-autosize-textarea'

class EditableTextField extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    cssClass: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    revertOnSave: PropTypes.bool,
    blurOnSave: PropTypes.bool,
    picker: PropTypes.object,
    actionComponent: PropTypes.object
  }

  state = {
    isEditing: false,
    updatedText: undefined, 
  }

  enterRegexp = /[\r\n]+/g
  textarea = undefined

  componentDidMount() {
    this.setState({
      isEditing: this.props.text === '',
      updatedText: undefined, 
    })
    this.textarea = undefined
  }

  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text || this.props.readOnly !== prevProps.readOnly) {
      this.setState({
        isEditing: this.props.text === '',
        updatedText: undefined, 
      })
      this.textarea = undefined
    }
  }

  inputRef = (ref) => {
    this.textarea = ref
  }
  onEdit = () => {
    const that = this
    const {readOnly} = this.props
    if (! readOnly) {
      this.setState({isEditing: true, updatedText: undefined})
      setTimeout(() => { that.textarea && that.textarea.focus()}, 100)
    }
  }
  onStopEdit = (revertChanges) => {
    const {text} = this.props
    const {updatedText} = this.state
    if (revertChanges || ! updatedText || text === updatedText) {
      this.setState({isEditing: text === ''})
      this.textarea = undefined
    }
  }
  onSave = () => {
    const text = this.state.updatedText || this.props.text
    const {onSave, revertOnSave, blurOnSave} = this.props
    if (onSave) {
      if (onSave(text)) {
        if (revertOnSave) {
          this.setState({updatedText: undefined})
        }
        if (blurOnSave) {
          this.setState({isEditing: false})
          this.textarea = undefined
        }
      }
    }
  }
  onKeyDown = (e) => {
    const {keyCode} = e
    if (keyCode === 27) { // Escape
      e.preventDefault()
      this.onStopEdit(true) // revert changes when I press escape
    }
    else if (keyCode === 10 || keyCode === 13) { // Enter | Return
      e.preventDefault()
      this.onSave()
    }
  }
  onChange = (e) => {
    const {enterRegexp} = this
    const {value} = e.target

    // we look for a suspicious CR|LF in the textarea just in case of pasting and replace them with plain 'ole spaces
    const strippedText = (value || '').replace(enterRegexp, ' ')
    if (value && value.length && strippedText.length < value.length) {
      this.setState({ updatedText: strippedText })
    }
    else {
      this.setState({ updatedText: value })
    }
  }

  render () {
    const {inputRef, onEdit, onStopEdit, onChange, onKeyDown, onSave} = this
    const {text, readOnly, placeholder, picker, cssClass, actionComponent} = this.props
    const {isEditing, updatedText} = this.state
    const isNotEditing = ! isEditing
    const hasChanged = (updatedText !== undefined && text !== updatedText)

    const element = (isEditing)
      ? <TextareaAutosize
          className='editable-text-field'
          ref={inputRef}
          rows={1}
          placeholder={placeholder || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onStopEdit}
          readOnly={readOnly}
          value={updatedText || text}
          />
      : <span
          onClick={onEdit}
          className={cssClass}
          >{updatedText || text}</span>

    return (
      <div className='editable-text-field-form'>
        {element}
        {isEditing && hasChanged && (
          <span>
            {picker}
            <button className='submit-button' onClick={onSave}>✓</button>
          </span>
        )}
        {isNotEditing && actionComponent}
      </div>
    )
  }
}

export default EditableTextField
