import React, { Component } from 'react'
import querystring from 'querystring'
import {Button} from 'evergreen-ui'
import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'

import {USER_QUERY, SAVE_CALENDAR_INTEGRATION, DELETE_CALENDAR_INTEGRATION} from '../../lib/queries'

import Loading from '../Loading'

import {PATH_SETTINGS, GCAL_OAUTH_CLIENT_ID} from '../../constants'
const CAL_PROVIDER = 'GOOGLE_CALENDAR'

class GCalSetup extends Component {
  constructor(props) {
    super(props)
    this.redirectUrl = `${document.location.protocol}//${document.location.host}${PATH_SETTINGS}`

    const hash = document.location.hash
    if (hash && hash.indexOf('#') === 0) {
      this.authContext = querystring.decode(hash.substr(1))
    }

    this.state = {
      connecting: false,
    }
  }

  componentDidMount() {
    const {authContext, props: {client}, redirectUrl} = this
    if (authContext && authContext.access_token) {
      this.setState({ connecting: true })
      const result = client.mutate({
        mutation: SAVE_CALENDAR_INTEGRATION,
        variables: {
          provider: CAL_PROVIDER,
          accessToken: authContext.access_token,
          tokenType: authContext.token_type,
          scope: authContext.scope,
        }
      })
      client.resetStore()
      window.location = redirectUrl
    }
  }

  onAuthRequest = (event) => {
    event.preventDefault()
    const params = [
      ['client_id', GCAL_OAUTH_CLIENT_ID],
      ['redirect_uri', this.redirectUrl],
      ['response_type', 'token'],
      ['scope', 'https://www.googleapis.com/auth/calendar.events.readonly'],
      ['include_granted_scopes', 'true'],
    ]
    const target = `https://accounts.google.com/o/oauth2/v2/auth?` 
      + params.map((a) => `${encodeURIComponent(a[0])}=${encodeURIComponent(a[1])}`).join('&')
    window.location = target
  }

  onDeleteIntegration = async (event) => {
    const {client} = this.props
    
    event.preventDefault()
    
    const calendarIntegration = this.activeCalendarIntegration()
    if (! calendarIntegration || ! calendarIntegration.id) {
      return
    }

    this.setState({ connecting: true })
    const result = client.mutate({
      mutation: DELETE_CALENDAR_INTEGRATION,
      variables: {id: calendarIntegration.id}
    })
    client.resetStore()
    this.setState({ connecting: false })
  }

  activeCalendarIntegration = () => {
    const { props: {data} } = this
    if (data.me) {
      return (data.me.calendarIntegrations || []).find((ci) => ci.provider === CAL_PROVIDER)
    }
    return undefined
  }

  render() {
    const { onAuthRequest, onDeleteIntegration, state: {connecting} } = this

    const {loading} = useQuery(USER_QUERY)
    if (connecting || loading) {
      return (
        <Loading />
      )
    }

    const calendarIntegration = this.activeCalendarIntegration()
    if (calendarIntegration) {
      return (
        <div>
          <p>Your account is connected to Google Calendar.</p>
          <Button appearance='default' onClick={onDeleteIntegration} iconBefore='delete'>Disconnect</Button>
        </div>
      )
    }

    return (
      <Button appearance='default' onClick={onAuthRequest} iconBefore='calendar'>Connect to Google Calendar</Button>
    )
  }
}

export default withApollo(GCalSetup)
