import React from 'react'
import PropTypes from 'prop-types'

function OneOnOnePicker(props) {
  const {options, prompt, selectedId, onChange} = props
  const opts = [{id:'', name:prompt || 'Pick a One on One'}]
    .concat(options)
    .map((opt) => <option value={opt.id} key={`agenda-one-on-one-${opt.id}`}>{opt.name}</option>)

  return <select value={selectedId} onChange={(e) => { onChange(e.target.value) }} className='pick-one-on-one'>{opts}</select>
}

OneOnOnePicker.propTypes = {
  options: PropTypes.array.isRequired,
  selectedId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  prompt: PropTypes.string,
}

export default OneOnOnePicker