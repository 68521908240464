import './EditableMarkdown.scss'
import 'react-mde/lib/styles/css/react-mde-all.css'

import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'evergreen-ui'

import ReactMarkdown from 'react-markdown'
import ReactMde from 'react-mde'

class EditableMarkdown extends React.Component {
  static propTypes = {
    editMode: PropTypes.bool,
    text: PropTypes.string,
    readOnly: PropTypes.bool,
    buttonsAlwaysVisible: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func
  }

  state = {
    updatedText: undefined,
    isEditing: false
  }

  componentDidMount() {
    if (! this.props.text) {
      this.setState({isEditing: true})
    }
    if (this.props.editMode !== undefined) {
      this.setState({isEditing: (this.props.editMode) ? true : false})
    }
  }
  componentDidUpdate(prevProps) {
    if (! this.props.text && prevProps.text !== this.props.text) {
      this.setState({isEditing: true})
    }
    if (this.props.editMode !== undefined && prevProps.editMode !== this.props.editMode) {
      this.setState({isEditing: (this.props.editMode) ? true : false})
    }
  }

  startEditing = () => {
    this.setState({isEditing: true})
  }
  textChanged = (value) => {
    this.setState({updatedText: value})
  }
  save = () => {
    this.props.onSave(this.state.updatedText || this.props.text)
    this.setState({isEditing: false, updatedText: undefined})
  }
  cancel = () => {
    this.setState({isEditing: false, updatedText: undefined})
    if (this.props.onCancel) this.props.onCancel(this)
  }

  render () {
    const {startEditing, textChanged, save, cancel} = this
    const {text, readOnly, buttonsAlwaysVisible} = this.props
    const {isEditing, updatedText} = this.state
    const hasChanged = (updatedText !== undefined && text !== updatedText)

    if (readOnly || (text && ! isEditing)) {
      return (
        <div className='markdown-container'>
          <ReactMarkdown
            source={text}
            skipHtml={true}
          />
          <Button onClick={startEditing}>edit</Button>
        </div>
      )
    }

    return (
      <div className='editable-markdown-container'>
        <ReactMde
          value={(hasChanged) ? updatedText : text}
          onChange={textChanged}
          minEditorHeight={150}
         />
        {(hasChanged || buttonsAlwaysVisible) && ! readOnly && (
          <div>
            <Button onClick={save}>save</Button>
            <Button onClick={cancel}>cancel</Button>
          </div>
        )}
      </div>
    )
  }
}

export default EditableMarkdown
