import './JournalEntryView.scss'

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import ReactMarkdown from 'react-markdown'

import EditableMarkdown from '../EditableMarkdown'

import {EDIT_JOURNAL} from '../../lib/queries'

function JournalEntryView(props) {
  const {journal} = props
  const [isEditing, setIsEditing] = useState(false)
  const [editJournal] = useMutation(EDIT_JOURNAL, {
    onCompleted: () => {
      setIsEditing(false)
    }
  })

  if (! isEditing) {
    return (
      <div className='journal-entry'>
        <span className='log-date'>
          {(new Date(journal.createdAt)).toDateString()}
          <button onClick={() => setIsEditing(true)} className='edit-journal'>[edit]</button>
        </span>
        <div className='journal-content'>
          <ReactMarkdown source={journal.text} skipHtml={true} />
        </div>
      </div>
    )
  }

  return (
    <div className='journal-entry'>
      <span className='log-date'>
        {(new Date(journal.createdAt)).toDateString()}
        <button onClick={() => setIsEditing(false)} className='stop-edit-journal'>[cancel]</button>
        <span className='doc-link'><a href='https://github.github.com/gfm/' target='_blank' rel='noopener noreferrer'>Github markdown</a></span>
      </span>
      <div className='journal-content'>
        <EditableMarkdown
          text={journal.text}
          editMode={true}
          onSave={(text) => {
            editJournal({ variables: { text, id: journal.id } });
          }}
          onCancel={() => setIsEditing(false)}
          />
      </div>
    </div>
  )
}

JournalEntryView.propTypes = {
  journal: PropTypes.object.isRequired
}

export default withApollo(JournalEntryView)
