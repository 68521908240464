import './OneOnOneView.scss'

import React, {useState} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Select } from 'evergreen-ui'

// import the dist to avoid webpack complaints about `require.extensions`
import Handlebars from 'handlebars/dist/handlebars'

import EditableMarkdown from '../EditableMarkdown'
import CreateTodoItem from '../Todo/CreateTodoItem'
import TodoItem from '../Todo/TodoItem'
import {AgendaList, CreateAgendaItem} from '../Agenda'
import {JournalEntryView} from '../Journal'
import EditOneOnOne from './EditOneOnOne'
import Loading from '../Loading'

import {ONEONONE_DETAIL_QUERY, EDIT_ONEONONE, CREATE_JOURNAL} from '../../lib/queries'

const JOURNALS_PER_BATCH = 5

Handlebars.registerHelper({
  eq: function (v1, v2) {
      return v1 === v2;
  },
  ne: function (v1, v2) {
      return v1 !== v2;
  },
  lt: function (v1, v2) {
      return v1 < v2;
  },
  gt: function (v1, v2) {
      return v1 > v2;
  },
  lte: function (v1, v2) {
      return v1 <= v2;
  },
  gte: function (v1, v2) {
      return v1 >= v2;
  },
  and: function () {
      return Array.prototype.slice.call(arguments).every(Boolean);
  },
  or: function () {
      return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  }
})

function createJournal(e, props) {
  const {data: {me: {meetingTemplates}, oneOnOne}} = props
  const {agenda, todos} = oneOnOne

  const {useDefault} = e
  let tmpl = undefined
  if (useDefault) {
    tmpl = 
`{{#unless (or agenda.length todos.length)}}
## Notes
{{/unless}}
{{#if agenda.length}}
## Agenda

{{#each agenda}}
### {{text}}

{{/each}}
{{/if}}

{{#if todos.length}}
## Open TODOs

{{#each todos}}
### {{text}}

{{/each}}
{{/if}}
`
  }
  else if (meetingTemplates && meetingTemplates.length > 0 && ! tmpl) {
    const selectedId = e.target.value
    if (selectedId) {
      const template = meetingTemplates.find((t) => (t && t.id && t.id === selectedId))
      tmpl = (template && template.text) ? template.text : ''
    }
  }
  if (tmpl) {
    const compiledTmpl = Handlebars.compile(tmpl)
    const text = compiledTmpl({agenda, todos, oneOnOne})
    return text
  }
  return false
}

function edited() {

}

function OneOnOneView(props) {
  const {id} = props.params

  const [journalBatches, setJournalBatches] = useState(1)
  const [showJournalForm, setShowJournalForm] = useState(false)

  const {data, loading} = useQuery(ONEONONE_DETAIL_QUERY, {
    variables: {id},
    fetchPolicy: "no-cache"
  })
  
  const [editOneOnOne] = useMutation(EDIT_ONEONONE)
  const [saveJournal, {client}] = useMutation(CREATE_JOURNAL, {
    onCompleted: () => {
      client.resetStore()
      setShowJournalForm(false)
    }
  })

  if (loading) {
    return <Loading />
  }

  const {me: {meetingTemplates}} = data
  const oneOnOne = data.oneOnOne || {}
  const {name, lastUpdate, todos, agenda, journal, notes} = oneOnOne

  const todoItems = todos
  .filter((t) => (! t.completedAt))
  .map((t) => <TodoItem key={`todo-item-${t.id}`} todo={t} oneOnOneId={id} />)

  const journalOutline = showJournalForm || ''

  const showJournalCount = journalBatches * JOURNALS_PER_BATCH
  const journalEntries = (journal || []).slice(0, showJournalCount).map((j) => <JournalEntryView journal={j} key={`journal-entry-${j.id}`} />)
  const hasMoreJournals = (journal || []).length > showJournalCount

  let lastMeeting = (lastUpdate) ? (new Date(lastUpdate)) : undefined
  lastMeeting = (lastMeeting && lastMeeting.toDateString) ? lastMeeting.toDateString() : 'never'

  const journalTemplateOptions = (meetingTemplates || []).map(t => <option key={`journal-tmpl-option'${t.id}`} value={t.id}>{t.name}</option>)

  return (
    <div className='oneonone-detail-container'>
      <div className='oneonone-name'>
        {name}
        <EditOneOnOne oneOnOne={oneOnOne} onEdited={edited} />
      </div>

      <div className='last-meeting'>
        Last meeting: <span className='date'>{lastMeeting}</span>
      </div>

      <div className='agenda-container'>
        <h3>Agenda</h3>
        {(agenda && agenda.length)
          ? <AgendaList agendaItems={agenda} onUpdate={() => {}} />
          : <p>No agenda yet</p>
        }

        <CreateAgendaItem oneOnOneId={oneOnOne.id} />          
      </div>

      <div className='todo-container'>
        <h3>Todo list</h3>
        {todos.length > 0 && todoItems}
        <CreateTodoItem oneOnOneId={oneOnOne.id} />
      </div>

      <div className='notes-container'>
        <h3>Notes</h3>
        <EditableMarkdown
          text={notes || ' '}
          onSave={(text) => {
            editOneOnOne({ variables: { notes: text, id: oneOnOne.id } });
          }}
          onCancel={() => {}}
          />
      </div>

      <div className='journal-container'>
        <h3>Journal</h3>
        {(showJournalForm) ? (
          <div className='journal-entry'>
            <EditableMarkdown
              text={journalOutline}
              buttonsAlwaysVisible={true}
              editMode={true}
              onSave={(text) => {
                saveJournal({ variables: { text, oneOnOne: oneOnOne.id } });
              }}
              onCancel={() => {setShowJournalForm(false)}}
              />
          </div>
        ) : (journalTemplateOptions && journalTemplateOptions.length > 0) ? (
          <Select onChange={(e) => { setShowJournalForm(createJournal(e, props)) }} defaultValue=''>
            <option value=''>+ new journal entry</option>
            {journalTemplateOptions}
          </Select>
        ) : (
          <Button iconBefore='plus' onClick={() => { setShowJournalForm(createJournal({useDefault: true}, props)) }}>
            new journal entry
          </Button>
        )
        }
        {journalEntries}
        {hasMoreJournals && <Button onClick={setJournalBatches(journalBatches + 1)}>show more...</Button>}
      </div>

    </div>
  )
}

export default OneOnOneView
