import './OneOnOneList.scss'

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import {CREATE_ONEONONE} from '../../lib/queries'
import EditableTextField from '../EditableTextField';

function CreateOneOnOne(props) {
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [createOneOnOne, {client}] = useMutation(CREATE_ONEONONE, {
    onCompleted: () => {
      client.clearStore() // TODO see if we need this...
      // TODO force reload or add created item to the one on one list
    },
    onError: (errors) => {
      const errs = errors.graphQLErrors || [{message: 'Sorry, something went wrong.'}]
      setErrorMessage(errs[0].message)
    }
  })

  return (
    <div className='one-on-one-item create'>
      <div className='wrapper'>
        {(errorMessage) ? <div className='error'>{errorMessage}</div> : undefined}
        <EditableTextField
          text=''
          placeholder='Create a new 1:1'
          revertOnSave={true}
          onSave={(text) => {
            createOneOnOne({ variables: { name: text } }); return true;
          }}
          />
      </div>
    </div>
  )
}

CreateOneOnOne.propTypes = {
  text: PropTypes.string
}

export default CreateOneOnOne
