import React from 'react'
import { browserHistory } from 'react-router'
import { withApollo } from '@apollo/client/react/hoc'

import { AUTH_TOKEN, PATH_SIGNED_OUT_HOMEPAGE } from '../constants'

class Signin extends React.Component {

  render() {
    setTimeout(this._signout, 100)
    return (
      <div>
        <p>Signing you out...</p>
      </div>
    )
  }

  _signout = () => {
    localStorage.removeItem(AUTH_TOKEN)
    this.props.client.resetStore()
    browserHistory.push(PATH_SIGNED_OUT_HOMEPAGE)
  }
}

export default withApollo(Signin)
