import './AgendaList.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'

import AgendaItem from './AgendaItem'
import {MOVE_AGENDA} from '../../lib/queries'

const SortableLI =  SortableElement(({value}) => value)

const SortableUL = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableLI key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  )
})

function agendaItems(itemList) {
  return itemList.filter((a) => (! a.resolvedAt))
}

function items(itemList, compact, onUpdate) {
  return agendaItems(itemList)
  .map((a) => <AgendaItem agenda={a} onUpdate={onUpdate} compact={compact} draggable />)
}

function updateForSort(evt, itemList) {
  const items = agendaItems(itemList)

  const {oldIndex, newIndex} = evt
  const itemToMove = items[oldIndex]
  const lastIndex = items.length - 1
  
  const variables = { id: itemToMove.id }
  if (newIndex <= 0) {
    variables.toBeginning = true
  }
  else if (newIndex >= lastIndex) {
    variables.toEnd = true
  }
  else {
    variables.afterId = items[newIndex-1].id
  }

  return variables
}

function AgendaList(props) {
  const {agendaItems, compact, onUpdate} = props
  const [moveAgenda] = useMutation(MOVE_AGENDA)
  // TODO force reload or add created item to the agenda list

  return (
    <div className='agenda-list'>
      <SortableUL
        items={items(agendaItems, compact, onUpdate)}
        onSortEnd={(evt) => {
          const variables = updateForSort(evt, agendaItems)
          console.log('variables', variables)
          moveAgenda({ variables })
        }}
        lockAxis='y'
        useDragHandle
      />
    </div>
  )
}

AgendaList.propTypes = {
  agendaItems: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  onUpdate: PropTypes.func
}

export default AgendaList
