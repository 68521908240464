import React from 'react'
import GCalSetup from './GCalSetup'

function Settings() {
  return (
    <div>
      <h2>Link with your Calendar</h2>
      <GCalSetup />
    </div>
  )
}

export default Settings
