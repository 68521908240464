import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import {toast} from 'react-toastify'

import {CREATE_AGENDA, ONEONONE_LIST_QUERY} from '../../lib/queries'
import EditableTextField from '../EditableTextField'
import {OneOnOnePicker} from '../OneOnOne'
import Loading from '../Loading'

function CreateAgendaItem(props) {
  const {oneOnOneId, placeholder} = props
  const [selectedOneOnOneId, setSelectedOneOnOneId] = useState(undefined)

  const {data, loading, error} = useQuery(ONEONONE_LIST_QUERY)
  const [createAgenda] = useMutation(CREATE_AGENDA, {
    onCompleted: (data) => {
      toast('Agenda added', {autoClose: 1000})
      setSelectedOneOnOneId(undefined)
    }
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    console.log(error)
    return <></>
  }

  const oneOnOnes = (data.me) ? (data.me.oneOnOnes || []) : []
  const picker = (oneOnOneId)
    ? undefined
    : <OneOnOnePicker
        options={oneOnOnes}
        prompt='Pick a 1:1'
        selectedId={selectedOneOnOneId}
        onChange={(id) => setSelectedOneOnOneId(id)}
        />
  
  return (
    <div className='agenda-item create'>
      <EditableTextField
        text=''
        placeholder={placeholder || 'Add to your agenda'}
        picker={picker}
        revertOnSave={true}
        onSave={(text) => {
          const id = oneOnOneId || selectedOneOnOneId
          if (id) {
            createAgenda({ variables: { text, oneOnOne: id } })
            return true
          }
          else {
            alert(`Please pick a One on One for this agenda topic`)
            return false
          }
        }}
        />
    </div>
  )
}

CreateAgendaItem.propTypes = {
  text: PropTypes.string,
  oneOnOneId: PropTypes.string,
  placeholder: PropTypes.string,
}

export default CreateAgendaItem
