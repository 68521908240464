import './AgendaItem.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import {toast} from 'react-toastify'

import EditableTextField from '../EditableTextField'

import {EDIT_AGENDA, RESOLVE_AGENDA} from '../../lib/queries'
import DragHandle from '../DragHandle';

function AgendaItem(props) {
  const {agenda, compact, draggable, onUpdate} = props
  const [markResolved] = useMutation(RESOLVE_AGENDA, {
    onCompleted: (data) => {
      if (onUpdate) onUpdate(data.resolveAgenda)
    }
  })
  const [saveText] = useMutation(EDIT_AGENDA, {
    onCompleted: (data) => {
      toast('Agenda updated', {autoClose: 1000})
      if (onUpdate) onUpdate(data.editAgenda)
    }
  })

  return (
    <li className='agenda-item'>
      {! compact && 
        <input
          type='button'
          className='finish-agenda'
          value='X'
          title='mark resolved'
          onClick={() => {
            markResolved({ variables: { id: agenda.id } });
          }}
          />
      }
      {draggable && <DragHandle />}
      <EditableTextField
        text={agenda.text}
        onSave={(text) => {
          saveText({ variables: { id: agenda.id, text } }); return true;
        }}
        />
    </li>
  )
}

AgendaItem.propTypes = {
  agenda: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  onUpdate: PropTypes.func,
  draggable: PropTypes.bool,
}

export default AgendaItem
