import './TodoState.scss'

import React, {useEffect, useRef, useReducer} from 'react'
import { useMutation } from '@apollo/client'
import {toast} from 'react-toastify'

import {START_TODO, COMPLETE_TODO, RESET_TODO, ONEONONE_DETAIL_QUERY} from '../../lib/queries'

function TodoState(props) {
  const {todo, oneOnOneId, ...otherProps} = props
  const isStarted = (todo.startedAt) ? true : false
  const isCompleted = (todo.completedAt) ? true : false
  const mutQuery = (isCompleted) ? RESET_TODO : (isStarted) ? COMPLETE_TODO : START_TODO
  // eslint-disable-next-line no-unused-vars
  const [_ignored, forceUpdate] = useReducer(x => x + 1, 0)

  const refetchQueries = (oneOnOneId) ? [{query: ONEONONE_DETAIL_QUERY, variables: {id: oneOnOneId}}] : []
  const [changeState] = useMutation(mutQuery, {
    refetchQueries,
    onCompleted: () => {
      if (todo.completedAt) {
        toast('Todo completed. Woo hoo!', {autoClose: 1000})
      }
      forceUpdate()
    }
  })

  const myRef = useRef()
  useEffect(() => {
    let isMounted = true
    if (isMounted && myRef.current) {
      myRef.current.indeterminate = (isStarted && ! isCompleted) ? true : undefined;  
    }

    // avoid trying to update after unmount by tracking when the component unmounts
    return () => {
      isMounted = false
    }
  })

  return (
    <input type='checkbox'
      checked={isCompleted}
      ref={myRef}
      onChange={() => {changeState({ variables: { id: todo.id } })}}
      {...otherProps} />
  )
}

export default TodoState
