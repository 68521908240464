import gql from 'graphql-tag'

export const USER_QUERY = gql`
query FetchMyTodos {
  me {
    id
    name
    email
    calendarIntegrations {
      id
      provider
      accessToken
      tokenType
      scope
    }
  }
}
`

export const TODO_QUERY = gql`
query FetchMyTodos {
  me {
    id
    todos {
      id
      text
      createdAt
      dueDate
      startedAt
      completedAt
      sort
      promisee {
        name
        id
      }
      oneOnOne {
        id
        name
      }
    }
  }
}
`

export const CREATE_TODO = gql`
mutation CreateTodo($text: String!, $oneOnOne: ID) {
  createTodo(
    text: $text
    oneOnOne: $oneOnOne
  ) {
    id
    text
    createdAt
    dueDate
    startedAt
    completedAt
    sort
    oneOnOne {
      id
      name
    }
  }
}
`

export const MOVE_TODO = gql`
mutation MoveTodo(
    $id: ID!
    $afterId: ID
    $toBeginning: Boolean
    $toEnd: Boolean
  ) {
  moveTodo(
    id: $id
    afterId: $afterId
    toBeginning: $toBeginning
    toEnd: $toEnd
  ) {
    id
    text
    createdAt
    dueDate
    startedAt
    completedAt
    sort
  }
}`

export const EDIT_TODO = gql`
mutation EditTodo($id: ID!, $text: String) {
  editTodo(
    id: $id
    text: $text
  ) {
    id
    text
    createdAt
    dueDate
    startedAt
    completedAt
    sort
  }
}
`

export const START_TODO = gql`
mutation StartTodo($id: ID!) {
  startTodo(
    id: $id
  ) {
    id
    text
    createdAt
    dueDate
    startedAt
    completedAt
    sort
  }
}
`
export const COMPLETE_TODO = gql`
mutation CompleteTodo($id: ID!) {
  completeTodo(
    id: $id
  ) {
    id
    text
    createdAt
    dueDate
    startedAt
    completedAt
    sort
  }
}
`
export const RESET_TODO = gql`
mutation ResetTodo($id: ID!) {
  resetTodo(
    id: $id
  ) {
    id
    text
    createdAt
    dueDate
    startedAt
    completedAt
    sort
  }
}
`
export const DELETE_TODO = gql`
mutation DeleteTodo($id: ID!) {
  deleteTodo(
    id: $id
  )
}
`

export const TEAM_LIST_QUERY = gql`
query FetchMyOneOnOnes {
  me {
    id
    teams {
      id
      name
    }
  }
}
`

export const TEAM_DETAIL_QUERY = gql`
query FetchTeam(
  $id: ID!
) {
  team(
    id: $id
  ) {
    id
    name
    manager {
      id
      name
      email
    }
    members {
      id
      name
      email
    }
  }
}
`


export const EDIT_TEAM = gql`
mutation EditTeam($id: ID!, $name: String!) {
  editTeam(
    id: $id
    name: $name
  ) {
    id
    name
  }
}
`

export const ONEONONE_LIST_QUERY = gql`
query FetchMyOneOnOnes {
  me {
    id
    oneOnOnes {
      id
      name
      lastUpdate
      todoCount
      agendaCount
      journalCount
      attendee {
        id
        name
      }
    }
    meetingTemplates {
      id
      name
      text
      editable
    }
  }
}
`

export const ONEONONE_DETAIL_QUERY = gql`
query FetchOneOnOne(
  $id: ID!
) {
  me {
    id
    meetingTemplates {
      id
      name
      text
    }
  }
  oneOnOne(
    id: $id
  ) {
    id
    name
    lastUpdate
    notes
    attendee {
      id
      name
      email
    }
    todos {
      id
      text
      createdAt
      startedAt
      completedAt
      sort
    }
    agenda {
      id
      text
      resolvedAt
    }
    journal {
      id
      text
      createdAt
    }
  }
}
`

// to add ^^^
// lastMeeting {
//   date
//   notes
// }

export const CREATE_ONEONONE = gql`
mutation CreateOneOnOne($name: String!) {
  createOneOnOne(
    name: $name
  ) {
    id
    name
    lastUpdate
  }
}
`

export const EDIT_ONEONONE = gql`
mutation EditOneOnOne($id: ID!, $name: String, $notes: String) {
  editOneOnOne(
    id: $id
    name: $name
    notes: $notes
  ) {
    id
    name
    notes
    lastUpdate
  }
}
`

export const DELETE_ONEONONE = gql`
mutation DeleteOneOnOne($id: ID!) {
  deleteOneOnOne(
    id: $id
  )
}
`

export const CREATE_AGENDA = gql`
mutation CreateAgenda($text: String!, $oneOnOne: ID!) {
  createAgenda(
    text: $text
    oneOnOne: $oneOnOne
  ) {
    id
    text
    resolvedAt
    oneOnOne {
      id
      name
    }
  }
}
`
export const EDIT_AGENDA = gql`
mutation EditAgenda($id: ID!, $text: String) {
  editAgenda(
    id: $id
    text: $text
  ) {
    id
    text
    resolvedAt
    oneOnOne {
      id
      name
    }
  }
}
`
export const MOVE_AGENDA = gql`
mutation MoveAgenda(
    $id: ID!
    $afterId: ID
    $toBeginning: Boolean
    $toEnd: Boolean
  ) {
  moveAgenda(
    id: $id
    afterId: $afterId
    toBeginning: $toBeginning
    toEnd: $toEnd
  ) {
    id
    text
    owner { id }
    oneOnOne { id }
    resolvedAt
  }
}`
export const RESOLVE_AGENDA = gql`
mutation FinishAgenda($id: ID!) {
  resolveAgenda(
    id: $id
  ) {
    id
    text
    resolvedAt
    oneOnOne {
      id
      name
    }
  }
}
`

export const CREATE_JOURNAL = gql`
mutation CreateJournal($text: String!, $oneOnOne: ID) {
  createJournal(
    text: $text
    oneOnOne: $oneOnOne
  ) {
    id
    text
    createdAt
    oneOnOne {
      id
      name
    }
  }
}
`
export const EDIT_JOURNAL = gql`
mutation EditJournal($id: ID!, $text: String) {
  editJournal(
    id: $id
    text: $text
  ) {
    id
    text
    createdAt
    oneOnOne {
      id
      name
    }
  }
}
`

export const SAVE_CALENDAR_INTEGRATION = gql`
mutation SaveCalendarIntegration($provider: CalendarProvider!, $accessToken: String!, $tokenType: String!, $scope: String!) {
  saveCalendarIntegration(provider: $provider, accessToken: $accessToken, tokenType: $tokenType, scope: $scope) {
    id
    provider
    accessToken
    tokenType
    scope
  }
}
`
export const DELETE_CALENDAR_INTEGRATION = gql`
mutation DeleteCalendarIntegration($id: ID!) {
  deleteCalendarIntegration(id: $id)
}
`
