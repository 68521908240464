import './TodoItem.scss'

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import {CREATE_TODO, ONEONONE_LIST_QUERY} from '../../lib/queries'
import EditableTextField from '../EditableTextField'
import {OneOnOnePicker} from '../OneOnOne'
import Loading from '../Loading'

function CreateTodoItem(props) {
  const {oneOnOneId, placeholder} = props
  const [selectedOneOnOneId, setselectedOneOnOneId] = useState(undefined)
  const {data, loading, error} = useQuery(ONEONONE_LIST_QUERY)
  const [createTodo] = useMutation(CREATE_TODO, {
    update(cache, {data: {createTodo}}) {
      cache.modify({
        id: cache.identify(data.me),
        fields: {
          todos(currentTodos=[]) {
            const newTodo = cache.identify(createTodo)
            return [...currentTodos, {__ref: newTodo}]
          }
        }
      })
      if (oneOnOneId) {
        cache.modify({
          id: cache.identify({__typename: 'OneOnOne', id: oneOnOneId}),
          fields: {
            todos(currentTodos=[]) {
              const newTodo = cache.identify(createTodo)
              return [...currentTodos, {__ref: newTodo}]
            }
          }
        })
      }
    },
    onCompleted: () => {
      toast('Todo added', {autoClose: 1000})
    }
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <>Error in CreateTodoItem</>
  }

  const oneOnOnes = (data.me) ? (data.me.oneOnOnes || []) : []
  const picker = (oneOnOneId)
    ? undefined
    : <OneOnOnePicker
        options={oneOnOnes}
        prompt='Link to a 1:1?'
        selectedId={selectedOneOnOneId}
        onChange={(id) => { setselectedOneOnOneId(id) }}
        />

  return (
    <div className='todo-item create'>
      <EditableTextField
        text=''
        revertOnSave={true}
        placeholder={placeholder || 'Add a task to your todo list'}
        picker={picker}
        onSave={(text) => {
          createTodo({ variables: { text, oneOnOne: oneOnOneId || selectedOneOnOneId } }); return true;
        }}
        />
    </div>
  )
}

CreateTodoItem.propTypes = {
  text: PropTypes.string,
  oneOnOneId: PropTypes.string,
  placeholder: PropTypes.string,
}

export default CreateTodoItem
