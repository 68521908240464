export const AUTH_TOKEN = 'auth-token'

export const PATH_SIGNED_IN_HOMEPAGE = '/app'
export const PATH_SIGNED_OUT_HOMEPAGE = '/'

export const PATH_SIGNIN = '/signin'
export const PATH_SIGNOUT = '/signout'

export const PATH_ONEONONES = `${PATH_SIGNED_IN_HOMEPAGE}/one-on-ones`
export const PATH_TODOS = `${PATH_SIGNED_IN_HOMEPAGE}/todos`
export const PATH_TEAMS = `${PATH_SIGNED_IN_HOMEPAGE}/teams`

export const PATH_SETTINGS = `${PATH_SIGNED_IN_HOMEPAGE}/settings`

export const GCAL_OAUTH_CLIENT_ID = '840770540531-hffo6o65g0l7joavvaj4iqel29po596q.apps.googleusercontent.com'
