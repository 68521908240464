import './NavBar.scss'

import React from 'react'
import { Link } from 'react-router'
import { withApollo } from '@apollo/client/react/hoc'
import { Button } from 'evergreen-ui'

import About from './About'

import { PATH_ONEONONES, PATH_TEAMS, PATH_TODOS, PATH_SETTINGS, PATH_SIGNED_IN_HOMEPAGE, PATH_SIGNIN, PATH_SIGNOUT } from '../constants'

class NavBar extends React.Component {

  state = {
    visible: false
  }

  clearCache = () => {
    const {client} = this.props
    client.resetStore()
  }

  toggleNav = () => {
    this.setState({visible: ! this.state.visible})
  }

  render () {
    const {isSignedIn} = this.props
    const {clearCache, toggleNav} = this
    const {visible} = this.state

    if (! isSignedIn) {
      return (
        <div className='navmenu'>
          <ul>
            <li>Northstar</li>
            <li><Link to={PATH_SIGNIN}>Sign in</Link></li>
            <li><Link href='/graphql'>API</Link></li>
          </ul>
        </div>
      )
    }

    let classes = 'navmenu'
    if (visible) classes = classes + ' visible'
    return (
      <ul className={classes} onClick={toggleNav}>
        <li><Link to={PATH_SIGNED_IN_HOMEPAGE}>Start here</Link></li>
        <li><Link to={PATH_TODOS}>Todos</Link></li>
        <li><Link to={PATH_ONEONONES}>1:1s</Link></li>
        <li><Link to={PATH_TEAMS}>Teams</Link></li>
        <li><Link to={PATH_SETTINGS}>Settings</Link></li>
        <li><Link to={PATH_SIGNOUT}>Sign out</Link></li>
        <li><Link href='/graphql'>API</Link></li>
        <li className='no-separator'><Button appearance='minimal' height={24} onClick={clearCache} iconBefore='refresh' /></li>
        <li className='no-separator'><About /></li>
      </ul>
    )
  }
}

export default withApollo(NavBar)

