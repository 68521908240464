import './OneOnOneList.scss'

import React from 'react'
import {Link} from 'react-router'
import { useQuery } from '@apollo/client'

import CreateOneOnOne from './CreateOneOnOne'
import {CreateAgendaItem} from '../Agenda'
import Loading from '../Loading'

import {ONEONONE_LIST_QUERY} from '../../lib/queries'
import {PATH_ONEONONES} from '../../constants'

function OneOnOneItem(props) {
  const {oneOnOne} = props

  return (
    <li>
      <Link to={`${PATH_ONEONONES}/${oneOnOne.id}`}>{oneOnOne.name}</Link>
      <br />
      {oneOnOne.lastUdate && <span className='last-update'>{oneOnOne.lastUdate}</span>}
      {oneOnOne.todoCount > 0 && <span className='todo-count'>{oneOnOne.todoCount} todo(s)</span>}
      <span className='agenda-count'>{(oneOnOne.agendaCount > 0) ? `${oneOnOne.agendaCount} agenda topic(s)` : 'no agenda'}</span>
    </li>
  )
}

function OneOnOneList(props) {
  const {compact} = props
  const {data, loading, error} = useQuery(ONEONONE_LIST_QUERY)

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <>Error loading 1:1 list</>
  }

  const oneOnOnes = data.me.oneOnOnes || []
  const oneOnOneItems = oneOnOnes
    .map((o) => <OneOnOneItem key={`oneonone-item-${o.id}`} oneOnOne={o} compact={compact} />)

  const compactStyle = (compact) ? 'compact' : ''
  return (
    <div className={`oneonone-list-container ${compactStyle}`}>
      {! compact && <CreateAgendaItem />}
      <ul>
        {oneOnOneItems}
      </ul>
      {! compact && <CreateOneOnOne />}
    </div>
  )
}

export default OneOnOneList