import 'tachyons'

import React from 'react'
import ReactDOM from 'react-dom'
import { Redirect, Router, Route, browserHistory } from 'react-router'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { setContext } from '@apollo/client/link/context'
import { ToastContainer, Slide } from 'react-toastify'

import { AUTH_TOKEN, PATH_ONEONONES, PATH_TEAMS, PATH_TODOS, PATH_SETTINGS, PATH_SIGNED_IN_HOMEPAGE, PATH_SIGNIN, PATH_SIGNOUT } from './constants'

import App from './components/App'
import {OneOnOneList, OneOnOneView} from './components/OneOnOne'
import Signin from './components/Signin'
import Signout from './components/Signout'
import TeamList from './components/TeamList'
import TeamView from './components/TeamView'
import {TodoList} from './components/Todo'
import Settings from './components/Settings'

import 'react-toastify/dist/ReactToastify.min.css'

var urlParams = new URLSearchParams(window.location.search)

const serviceUri = (urlParams.has("local-dev")) ? 'http://localhost:8000/graphql' : window.location.origin + '/graphql';
const httpLink = createHttpLink({ uri: serviceUri });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-token': token ? token : '',
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

window.launchApp = () => {
  const mktgDiv = document.getElementById('marketing')
  mktgDiv.parentNode.removeChild(mktgDiv);

  ReactDOM.render((
    <ApolloProvider client={client}>
      <Router history={browserHistory}>
        <Redirect from='/' exact to={PATH_SIGNED_IN_HOMEPAGE} />
        <Route path={PATH_SIGNED_IN_HOMEPAGE} component={App}>
          <Route path={PATH_TEAMS} component={TeamList} />
          <Route path={`${PATH_TEAMS}/:id`} component={TeamView} />
          <Route path={PATH_TODOS} component={TodoList} />
          <Route path={PATH_ONEONONES} component={OneOnOneList} />
          <Route path={`${PATH_ONEONONES}/:id`} component={OneOnOneView} />
          <Route path={PATH_SETTINGS} component={Settings} />
        </Route>
        <Route exact path={PATH_SIGNIN} component={Signin} />
        <Route exact path={PATH_SIGNOUT} component={Signout} />
      </Router>
      <ToastContainer  autoClose={3000} transition={Slide} />
    </ApolloProvider>
    ),
    document.getElementById('root')
  )
}

if (window.location.pathname !== '/' || urlParams.has('launch')) {
  window.launchApp()
}
