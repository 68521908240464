import './TeamList.scss'

import React from 'react'
import {Link} from 'react-router'
import { useQuery } from '@apollo/client'

import Loading from './Loading'

import {TEAM_LIST_QUERY} from '../lib/queries'
import {PATH_TEAMS} from '../constants'

const TeamItem = (props) => <li><Link to={`${PATH_TEAMS}/${props.team.id}`}>{props.team.name}</Link></li>

const CreateTeam = (props) => <p>Add a new team! (coming soon)</p>

function TeamList(props) {
  const {compact} = props
  const {data, loading} = useQuery(TEAM_LIST_QUERY)

  if (loading) {
    return <Loading />
  }

  const teams = data.me.teams || []
  const teamItems = teams
    .map((t) => <TeamItem key={`team-item-${t.id}`} team={t} compact={compact} />)

  const compactStyle = (compact) ? 'compact' : ''
  return (
    <div className={`team-list-container ${compactStyle}`}>
      <ul>
        {teamItems}
      </ul>
      {! compact && <CreateTeam />}
    </div>
  )
}

export default TeamList