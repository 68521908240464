import React from 'react'
import { useQuery } from '@apollo/client'
import { browserHistory } from 'react-router'

import NavBar from './NavBar'
import AppContent from './AppContent'
import HomeView from './HomeView'
import Loading from './Loading'

import newrelic from '../lib/newrelic'
import {USER_QUERY} from '../lib/queries'
import { PATH_SIGNIN } from '../constants'

export default function App(props) {
  const {data, loading, error} = useQuery(USER_QUERY)

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`
  
  if (data.me) {
    const {id, email, name} = data.me
    newrelic.setCustomAttribute('userId', id||'')
    newrelic.setCustomAttribute('userEmail', email||'')
    newrelic.setCustomAttribute('userDomain', (email||'').split('@').pop())
    newrelic.setCustomAttribute('userName', name||'')
  }
  else {
    setTimeout(() => {browserHistory.push(PATH_SIGNIN)}, 50)
    return <Loading />
  }

  return (
    <div>
      <NavBar isSignedIn={true} />
      <AppContent>
        {props.children || <HomeView />}
      </AppContent>
    </div>
  )
}
